$break-small: 320px;
$break-large: 1200px;

body {
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}

h4, .h4 {
  font-size: 1.125rem;
}


.fullPageWrapper {
  width: 100%;
  padding-top: 0;

  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}

.contentWrapper {
  width: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}


.background-picture:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  background: 70% 20% no-repeat;
  background-size: cover;

  background-image: url("/images/signup/bg.jpg");
  z-index: 1;
  -webkit-animation: fadeBack 20s linear infinite;
  animation: fadeBack 20s linear infinite;
}

.login {
  padding: 20px;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: left;
  height: 100vh;
}



.field-error {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}


.countrySelection {
  display: flex;


  input{
    margin:0;
    padding:0;
    appearance:none !important;
    display: none;
  }


  .country {
    background: #c9ccd7;
    border-radius: 10px;
  }

}


.cc-selector
.visa{background-image:url(http://i.imgur.com/lXzJ1eB.png);}
.mastercard{background-image:url(http://i.imgur.com/SJbRQF7.png);}

.cc-selector input:active +.drinkcard-cc{opacity: .9;}
.cc-selector input:checked +.drinkcard-cc{
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}
.drinkcard-cc{
  cursor:pointer;
  background-size:contain;
  background-repeat:no-repeat;
  display:inline-block;
  width:100px;height:70px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
  -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
  filter: brightness(1.8) grayscale(1) opacity(.7);
}
.drinkcard-cc:hover{
  -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
  -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
  filter: brightness(1.2) grayscale(.5) opacity(.9);
}



.form-group .form-control {
  background: transparent;
  border-radius: .3rem !important;
  font-size: .9375rem;
}


.form-control, .asColorPicker-input, .dataTables_wrapper select, .jsgrid .jsgrid-table .jsgrid-filter-row input[type=text], .jsgrid .jsgrid-table .jsgrid-filter-row select, .jsgrid .jsgrid-table .jsgrid-filter-row input[type=number], .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead, .tt-query, .tt-hint {
  border: 1px solid #c9ccd7;
  font-weight: 400;
  font-size: 0.875rem;
}


.form-control-lg {
  height: 3.175rem;
  padding: 0.94rem 1.94rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}


.register-form-btn {
  text-transform: uppercase;
  padding: 1rem 3rem;
  line-height: 1.5;
}

.btn.btn-lg {
  font-size: 0.875rem;
}


.loginContainer {
  width: 100%;
  @media screen and (min-width: $break-small) {
    height: 100vh;
  }

  &.row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

}


.font-weight-light {
  font-weight: 300 !important;
}


.regHeader {
  text-align: center;
}


.loadButton {
  position: relative;

  .loadSpinner {
    width: 20px;
    height: 20px;
    display: block;
    color: white;
    position: absolute;
    left: 20px;
  }

}




.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #f5f5f5;
  font-size: 13px;
}


.navbar {
  display: flex;
  justify-content: center;
}


.content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: stretch;


  .form-group {
    width: 100%;
  }
}




.SlideIn-appear {
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
  opacity: 0;
}

.SlideIn-appear.SlideIn-appear-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 0.6s linear;
}
.SlideIn-enter {
  opacity: 0;
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
}
.SlideIn-enter.SlideIn-enter-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 0.2s linear 0.4s;
}
.SlideIn-leave {
  opacity: 1.0;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
}
.SlideIn-leave.SlideIn-leave-active {
  opacity: 0;
  position: absolute;
  width: 100%;
  transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transition: all 0.2s linear;
}







.SlideOut-appear {
  transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  opacity: 0;
}

.SlideOut-appear.SlideOut-appear-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 0.6s linear;
}
.SlideOut-enter {
  opacity: 0;
  transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
}
.SlideOut-enter.SlideOut-enter-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 0.2s linear 0.4s;
}
.SlideOut-leave {
  opacity: 1.0;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
}
.SlideOut-leave.SlideOut-leave-active {
  opacity: 0;
  position: absolute;
  width: 100%;
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transition: all 0.2s linear;
}
